@import "colors.scss";

h1, h2, h3, h4, h5, h6 {
    color: inherit;
    font-family: inherit;
    font-weight: bold;
    margin: 10px 0;

    line-height: 1;
}

pre {
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
    font-size: inherit;
}

.btn {
    border-radius: 0;
    padding: 0 !important;
}

button {
    background-color: transparent;
    border: none;
}

button:focus {
    border: 1px solid $red;
}

select,
textarea,
input {
    border-radius: 0;
    padding: 2px 3px;
    border: 1px solid $default-border;
}

textarea:focus,
input:focus {
    transition-property: none;
    box-shadow: none;
    outline: 0 none;
}

/* input append, for timepicker */

.input-append {
    white-space: nowrap;
    
    > input,
    > select {
        display: inline-block;
        margin-bottom: 0;
        position: relative;
        vertical-align: top;
        height: 28px;    
    }
    
    .add-on,
    .btn,
    .btn-group {
        margin-left: -5px;
        height: 28px;
        vertical-align: top;
    }
    
    .add-on {
        border: 1px solid $default-border;
        display: inline-block;
        font-size: 14px;
        font-weight: normal;
        min-width: 16px;
        padding: 4px 5px;
        text-align: center;
        width: auto;    
    }
}

.append {
    display: inline-block;
    
    > * {
        display: inline-block;
        
        border: 1px solid $default-border;

        margin-left: -5px;
    }
    
    > *:first-child {
        margin-left: 0px;
    }
    
    input[type=text] {
        width: 100%;
        height: 100%;
        
        border: none;
        
        padding: 0 8px;
        
        vertical-align: middle;
    }
}

/* btn-group */

.btn-group {
    border: 1px solid $default-border;
    
    .btn {
        border-color: $default-border;
        border-top: none;
        border-bottom: none;    
    }
    
    .btn:first-child {
        border-left: none;
    }
    
    .btn:last-child {
        border-right: none;
    }
}

/* BS alert */

.alert {
    background-color: #FCF8E3;
    color: #C09853;

    border-radius: 0;
    margin-bottom: 0;
}

.alert-danger,
.alert-error {
    background-color: #F2DEDE;
    border-color: #EED3D7;
    color: #B94A48;
}

.alert-success {
    background-color: #DFF0D8;
    border-color: #D6E9C6;
    color: #468847;
}

/* Timepicker fix */

.bootstrap-timepicker .dropdown-menu {
    width: auto;
}

/* USIM */

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

button:disabled {
    visibility: hidden;
}